import React, { useContext, useEffect, useRef, useState } from 'react'
import { DataContext } from '../../context/DataContext'
import ScrollToTop from '../scrollToTop/ScrollToTop'
import { useParams, useHistory  } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap'
import { decodeHTML } from '../../modules/utilities'
import SectionWithTouch from '../shared/SectionWithTouch'

const TrainingDetail = () => {
  const { trainingData: data } = useContext(DataContext)
  const history = useHistory()
  const [training, setTraining] = useState()
  const currentIndex = useRef(-1)
  const [previousTrainingItem, setPreviousTrainingItem] = useState()
  const [nextTrainingItem, setNextTrainingItem] = useState()
  const [initialClientX, setInitialClientX] = useState(0)
  const [finalClientX, setFinalClientX] = useState(0)

  let { trainingpath } = useParams()

  useEffect(() => {
    if (training && currentIndex.current !== undefined) {
      if (currentIndex.current > 0) {
        setPreviousTrainingItem(data[currentIndex.current - 1])
      } else {
        setPreviousTrainingItem(undefined)
      }

      if (currentIndex.current < data.length - 1) {
        setNextTrainingItem(data[currentIndex.current + 1])
      } else {
        setNextTrainingItem(undefined)
      }      
    }
  }, [data, training])
  
  useEffect(() => {
    if (data && trainingpath) {
      let index
      for (let i = 0; i < data.length; i++) {
        if (data[i].path === trainingpath) {
          index = i
          break
        }
      }

      const thisService = data.filter((item, i) => {
        return item.path === trainingpath
      })[0]

      if (index !== undefined) {
        setTraining(thisService)
        currentIndex.current = index
      } else {
        history.push('/training')
      }
    }
  }, [data, trainingpath, history])

  const handlePageClick = (next) => {
    let gotoPage
    if (next) {
      gotoPage = data[currentIndex.current + 1]
    } else {
      gotoPage = data[currentIndex.current - 1]
    }

    if (gotoPage) {
      history.push(`/training/${gotoPage.path}`)
    }
  }


  return (
    <>
      {(previousTrainingItem || nextTrainingItem) &&
        <Container>
          {currentIndex.current !== undefined &&
            <Row style={{position: 'relative', minHeight: '25px'}}>
              {previousTrainingItem &&
                <button className="pageButton" onClick={() => handlePageClick(false)}><i className="icofont-simple-left"></i>{previousTrainingItem.name}</button>
              }
              <div className="dotContainer">
                {data && data.map((item, i) => { 
                  return <span key={`key_${i}`} className={`dot ${i === currentIndex.current ? 'active' : ''}`}></span>
                })}
              </div>
              {nextTrainingItem &&
                <button className="pageButton right" onClick={() => handlePageClick(true)}>{nextTrainingItem.name}<i className="icofont-simple-right"></i></button>
              }
            </Row>
          }
        </Container>
      }
      <SectionWithTouch className="services" handleTouchEnd={handlePageClick}>
        <Container>
          <ScrollToTop />
          {training && 
            <Row>
              <Col sm={12}>
                <h4>Training: {training.name}</h4>
              </Col>
              <Col sm={12} className="misc-content"
                dangerouslySetInnerHTML={{__html: decodeHTML(training.course_outline)}}
              />
            </Row>
          }
        </Container>
      </SectionWithTouch>
    </>
  );
}

export default TrainingDetail