import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap'
import { DataContext } from '../../context/DataContext'
import ScrollToTop from  '../scrollToTop/ScrollToTop'

const Portfolio = () => {
  const { demosData } = useContext(DataContext)

  return (
    <section id="portfolio" className="portfolio">
      <ScrollToTop />
      <Container>
        <div className="section-title" data-aos="fade-up">
        <h2>Some of our <strong>recent works</strong></h2>
        </div>
        <Row className="portfolio-container" data-aos="fade-up">
          {demosData &&
            demosData.map((demo, i) => {
            return <Col key={`key_${i}`} lg={4} md={6} className="portfolio-item filter-app">
                <Link to={`/demos/${demo.path}`}  data-aos="fade-up">
                  <div className="portfolio-info">
                    <h4>{demo.title}</h4>
                  </div>              
                  <div className="demo-logo">
                    <img src={`./assets/demos/${demo.icon}`} className="img-fluid" alt="" />
                  </div>
                </Link>
              </Col>    
            })
          }
        </Row>
      </Container>
    </section>
  );
}

export default Portfolio