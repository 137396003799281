import React, { useContext, useEffect, useState } from 'react'
import { DataContext } from '../../context/DataContext'
import ScrollToTopOnMount from '../scrollToTop/ScrollToTopOnMount'
import { useParams, useHistory } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap'
import { decodeHTML } from '../../modules/utilities'

const ClientDetail = () => {
  const { clientsData } = useContext(DataContext)
  const history = useHistory()
  const [client, setClient] = useState()
  let { clientpath } = useParams()

  useEffect(() => {
    if (clientsData && clientpath) {
      const thisClient = clientsData.filter(item => item.path === clientpath)[0]

      if (thisClient) {
        setClient(thisClient)
      } else {
        history.push('/clients')
      }
    }
  }, [clientsData, clientpath, history])

  return (
    <section id="clients" className="clients">
      <ScrollToTopOnMount />
      <Container>
        <Row>
          {client && 
            <Col sm={12} id="content-col">
              {/* <p>
                <a href="clients.php" className="back-button">BACK</a>
              </p> */}
              <Col xs={12} sm={6} md={4} className="imgContainer">
                <a href={client.client_site_url} target="_blank" rel="noopener noreferrer">
                  <img src={`/assets/clients/${client.client_logo}`} className="img-fluid" />
                </a>
              </Col>
              <Row>
                <h4>Industry: {client.client_industry}</h4>
              </Row>
              <Row className="misc-content"
                dangerouslySetInnerHTML={{__html: decodeHTML(client.client_desc)}}
              />
            </Col>
          }
        </Row>
      </Container>
    </section>
  );
}

export default ClientDetail