import React from 'react';
import ScrollToTopOnMount from '../scrollToTop/ScrollToTopOnMount'
import ClientsList from '../clients/ClientsList'

function Clients() {
  return (
    <>
      <ScrollToTopOnMount />
      <ClientsList />
    </>
  );
}

export default Clients