import React from 'react';
import AboutDetail from '../about/AboutDetail'
import ClientsList from '../clients/ClientsList'
import ScrollToTop from  '../scrollToTop/ScrollToTop'

function About() {
  return (
    <>
      <ScrollToTop />
      <AboutDetail />
    </>
  );
}

export default About
