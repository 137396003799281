import React from 'react';

function PartnerSocials({ item}) {
  return (
    <div className="float-right share">
      {item.facebook && item.facebook !== '#' &&
        <a target="_blank" rel="noopener noreferrer" href={item.facebook} title="Share on Facebook"><i className="icofont-facebook"></i></a>
      }
      {item.twitter && item.twitter !== '#' &&
        <a target="_blank" rel="noopener noreferrer" href={item.twitter} title="Share on Twitter"><i className="icofont-twitter"></i></a>
      }
      {item.instagram && item.instagram !== '#' &&
        <a target="_blank" rel="noopener noreferrer" href={item.instagram} title="Share on Instagram"><i className="icofont-instagram"></i></a>
      }
      {item.linkedin && item.linkedin !== '#' &&
        <a target="_blank" rel="noopener noreferrer" href={item.linkedin} title="Share on Linked In"><i className="icofont-linkedin"></i></a>
      }
      {item.youtube && item.youtube !== '#' &&
        <a target="_blank" rel="noopener noreferrer" href={item.youtube} title="Share on You Tube"><i className="icofont-youtube"></i></a>
      }
      
    </div>
  );
}

export default PartnerSocials
