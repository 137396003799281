import React, { useContext } from 'react';
import Particles from 'react-particles'
import { DataContext } from '../../context/DataContext'

const Hero = () => {
  const { particleConfig } = useContext(DataContext)

  return (
    <section id="hero">
      <figure style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', background: 'linear-gradient(-90deg, rgb(1,46,87), rgb(90,186,236))'}}>
        <div id="particles-js">
          {particleConfig && 
            <Particles 
              style={{width: '100%', height: '100%'}}
              params={particleConfig} 
            /> 
          }
        </div>
      </figure>
      <div className="hero-content" data-aos="fade-up">
        <h2 className="digi-font-light">Welcome to <span>Digimasters</span></h2>
        <p className="digi-font-light">Welcome to Digimasters Ltd, a pioneering digital and data analytics consultancy founded by Adam Nagus, dedicated to transforming businesses through innovative strategies and cutting-edge technology. Our team excels in delivering comprehensive services from data strategy to go-to-market strategies, mentoring leaders, and driving growth in enterprises and start-ups alike.</p>
      </div>
    </section>
  );
}

export default Hero