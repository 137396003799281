import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap'
import { DataContext } from '../../context/DataContext'

const icons = [
  'icofont-paperclip',
  'icofont-chat',
  'icofont-chart-bar-graph',
  'icofont-computer',
  'icofont-link',
  'icofont-people'
]

const ServicesList = () => {
  const { servicesData } = useContext(DataContext)
  const [visibleData, setVisibleData] = useState()

  useEffect(() => {
    if (servicesData) {
      setVisibleData(servicesData.filter(i => i.visible === 1))
    }
  }, [servicesData])

  return (
    <section id="services" className="services">
      <Container>
        <div className="section-title" data-aos="fade-up">
          <h2>Check out <strong>our services</strong></h2>
        </div>
        <Row data-aos="fade-up">
          {visibleData &&
            visibleData.map((service, i) => {
            return <Col key={`key_${i}`} lg={6} md={6} xs={12}>
              <Link to={`/services/${service.path}`}>
                <div className="icon-box" data-aos="fade-up">
                  <div className="icon"><i className={icons[i % 6]}></i></div>
                  <h1 className="title">{service.name}</h1>
                  <div className="descContainer">
                    <p className="description">{service.short_desc}</p>
                  </div>
                </div>
              </Link>
            </Col>
            })
          }
        </Row>
      </Container>
    </section>
  );
}

export default ServicesList