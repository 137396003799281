import React, { useContext, useEffect, useState } from 'react'
import { DataContext } from '../../context/DataContext'
import ScrollToTopOnMount from '../scrollToTop/ScrollToTopOnMount'
import { useParams, useHistory } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap'
import { decodeHTML } from '../../modules/utilities'
import PartnerSocials from './PartnerSocials'

const PartnerDetail = () => {
  const { partnerData } = useContext(DataContext) // if there are going to lots of blogs might want to change this to pre-select the blog entry
  const history = useHistory()
  const [data, setData] = useState()
  let { partnerpath } = useParams()

  useEffect(() => {
    if (partnerData && partnerpath) {
      const thisData = partnerData.filter(item => item.path === partnerpath)[0]

      if (thisData) {
        setData(thisData)
      } else {
        history.push('/partners')
      }
    }
  }, [partnerData, partnerpath, history])

  return (
    <section id="partner" className="blog partner">
      <ScrollToTopOnMount />
      <Container>
        <Row>
          <Col className="entries">
            {data && 
              <article className="entry entry-single" data-aos="fade-up">
                <div className="client-logo" data-aos="fade-up">
                  <img src={`/assets/partners/${data.logo}`} className="img-fluid" alt="" />
                </div>
                <div className="entry-details">
                  {/* <div className="share">
                    <PartnerSocials item={data} />
                  </div> */}
                  <h2 className="entry-title" data-aos="fade-up">{data.name}</h2>
                  {/* <div className="entry-meta">
                    <ul>
                      <li className="d-flex align-items-center"><i className="icofont-folder"></i> <a href="blog-single.html">{data.type}</a></li>
                    </ul>
                  </div> */}
                  <div className="entry-content misc-content">
                    <div className="misc-content" dangerouslySetInnerHTML={{__html: decodeHTML(data.long_desc)}}></div>
                  </div>
                </div>

                <div className="entry-footer clearfix">
                  <div className="float-left">
                    <i className="icofont-folder"></i>
                    <ul className="cats">
                      <li><span className="tagName">{data.type}</span></li>
                    </ul>
                  </div>

                  <div className="float-right share">
                    <PartnerSocials item={data} />
                  </div>
                </div>
              </article>
            }
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default PartnerDetail