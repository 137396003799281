import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { DataContext } from '../../context/DataContext'
import { Container, Row, Col } from 'react-bootstrap'
import { decodeHTML } from '../../modules/utilities'
import Videos from './Videos'

function AboutDetails() {
  const { aboutData } = useContext(DataContext)

  return (
    <section id="about-us" className="about-us">
      <Container>
        <Row className="no-gutters">
          <Col xl={4} className="image ml-lg-5 ml-xl-0 d-flex align-items-stretch justify-content-center justify-content-lg-start" data-aos="fade-right"></Col>
          <Col xl={8} className="col-xl-8 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch">
            <div className="content d-flex flex-column justify-content-center">
              <h3 data-aos="fade-up" className="digi-font">Digimasters</h3>
              <p>Founded in 2017 by <a href="https://www.linkedin.com/in/adamnagus/" target="_blank">Adam Nagus</a>, Digimasters Ltd has swiftly evolved from its origins in data strategy and visual analytics to a comprehensive digital and data analytics consultancy. Initially carving its niche in mentoring and analytics delivery, our expertise has significantly broadened to encompass vital services for Private Equity and Enterprise entities, particularly in the realms of Merger and Acquisition. Our growth trajectory didn't stop there; Digimasters has ventured into offering specialised services for Marketing, Product Owners, and Sales, mastering the art of crafting potent Go-To-Market (GTM) strategies.</p>

              <p>At the heart of our expansion is the integration of cutting-edge A.I., Data, and Digital delivery capabilities, seamlessly combined with our acumen in Due Diligence, GTM, and sales. This unique blend empowers us to offer unparalleled marketing, blogging, event management, and campaign management automations, all turbocharged by Generative A.I. and workflow automation. Our commitment extends beyond service delivery to fostering growth and innovation within the community, mentoring CxOs across diverse organizations, and providing a launchpad for start-ups and scale-ups poised for hyper-growth. Digimasters Ltd stands as a beacon of innovation and expertise, dedicated to propelling our clients and their ventures to unprecedented heights.</p>
            </div>
          </Col>
        </Row>

        <Row className="no-gutters">
          <Col xl={12} className="col-xl-8 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch">
              <div className="content d-flex flex-column justify-content-center">
                <h3 data-aos="fade-up" className="digi-font">Adam Nagus - The Journey</h3>
                <p>Adam Nagus, has over 20 years of experience in leading transformation and innovation projects. His expertise spans strategy, delivery, and programme management, covering the full lifecycle of programmes from inception to completion. Adam is known for his role in mentoring current business leaders and driving changes in diversity and innovation, ensuring technology advancements are effectively integrated into businesses. Beyond leading Digimasters, he also dedicates time to mentoring across various aspects of business, including enterprise architecture, go-to-market strategies, and M&A processes. Adam's pragmatic approach to leadership and innovation has significantly contributed to the company's success and the development of its strategies.</p>

                <p>Adam's achievements include:
                  <ul>
                    <li>DataIQ Top 100 data leaders 2023</li>
                    <li>Top Data Leader 2022 - CDO Magazine</li>
                    <li>Qlik and Tableau Partner of the Year (Accenture) 2013-2017</li>
                    <li>Chairperson and advisor for the CIO Institute</li>
                    <li>Global Chief Data Officer for FTSE 100 Enterprise</li>
                  </ul>
                </p>
              </div>
            </Col>

          <Col xl={12} className="col-xl-12 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch">
            <div className="content d-flex flex-column justify-content-center">
              <h4>CEO - Digimasters (2017 - Current)</h4>
              <Row className="no-gutters">
                <Col xl={2} className="image-logo dm-logo" data-aos="fade-right"></Col>
                <Col xl={10} className="col-xl-10 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch">
                  <p>Adam founded Digimasters After leaving Accenture and has continued to run Digimasters through an acquisition by FTI Consulting which transformed into an investment and partial merger of employees and services. Digimasters continued to grow and develop as Adam adapted the services and his own fractional career through his service to FTI Consulting and then during his tenure as Global CDO for Pearson PLC.</p>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xl={12} className="col-xl-12 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch">
            <div className="content d-flex flex-column justify-content-center">
              <h4>Global Chief Data Officer - Pearson (2021 - 2023)</h4>
              <Row className="no-gutters">
                <Col xl={2} className="image-logo pearson-logo" data-aos="fade-right"></Col>
                <Col xl={10} className="col-xl-10 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch">
                  <p>Adam was the first CDO for Pearson the 200-year-old Education, Media and Publishing company with revenues over $5 Billion a workforce over 20,000 individuals across over 20 countries.</p>
                </Col>
              </Row>
            </div>
          </Col>

          <Col xl={12} className="col-xl-12 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch">
            <div className="content d-flex flex-column justify-content-center">
              <h4>Managing Director - Digital Delivery - FTI Consulting (2018 - 2021)</h4>
              <Row className="no-gutters">
                <Col xl={2} className="image-logo fti-logo" data-aos="fade-right"></Col>
                <Col xl={10} className="col-xl-10 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch">
                  <p>Adam join FTI Consulting to support the creation of the new Digital Science practice and took on the responsibility for all the Digital Delivery capabilities for the newly formed practice. Key Industry and example accounts include: Money Super Market (Insurance), Macquarie (Private Equity), Wheelabrator (Clean Energy), DAS(Insurance) DLG(Insurance) Pearson(Media), EBRD(Banking)</p>
                </Col>
              </Row>
            </div>
          </Col>

          <Col xl={12} className="col-xl-12 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch">
            <div className="content d-flex flex-column justify-content-center">
              <h4>Global Director for Visual Intelligence - Accenture (2004 - 2017)</h4>
              <Row className="no-gutters">
                <Col xl={2} className="image-logo acn-logo" data-aos="fade-right"></Col>
                <Col xl={10} className="col-xl-10 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch">
                  <p>Adam joined Accenture as the still current largest digital transformation programme in Europe was kicking off. The digitisation of patient records or the £12B national programme for IT (npfIT). Adam became the head of reporting and data migration for the North and East of England for npfIT, before moving on to become a leader in Business Intelligence and founding the innovative new practice Visual Analytics. Visual Analytics is a groundbreaking new methodology, which focuses on user centric design instead of inefficient requirement gather techniques used in business intelligence. Adam developed a new discipline to take advantage of new product development techniques and applied them to data analytics. Empathy for the user instead of black and white box ticking. Industry experience includes: NHS, CPWH, Vodafone, BBC, RSA, MoJ, Rio Tinto, BP, Shell, Shire, Merck, Novartis, Skype, RMG</p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AboutDetails
