import React from 'react'
import Hero from '../hero/Hero'
import ClientsList from '../clients/ClientsList'
import ServicesList from '../services/ServicesList'
import Demos from '../demos/DemosList'

function Home() {
  return (
    <div className="App">
      <Hero />
      <ServicesList />
      <ClientsList />
      {/* <Demos /> */}
    </div>
  );
}

export default Home
