import React, { useEffect, useState} from 'react'
import { useLocation } from "react-router-dom";

const BurgerMenu = () => {
  const location = useLocation();
  const [toggle, setToggle] = useState()

  useEffect(() => {
    setToggle(false)
  }, [location])

  useEffect(() => {
    if (toggle) {
      document.getElementsByTagName('body')[0].classList.add('mobile-nav-active')
    } else {
      document.getElementsByTagName('body')[0].classList.remove('mobile-nav-active')
    }
  }, [toggle])

  const handleClick = () => {
    setToggle(prev => !prev)
  }

  return  <>
    <button type="button" className="mobile-nav-toggle d-lg-none" onClick={handleClick}>
      <i className={toggle ? 'icofont-close' : 'icofont-navigation-menu'}></i>
    </button>        
    <div className="mobile-nav-overly"></div>
  </>
}

export default BurgerMenu