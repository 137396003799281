import React, { useState, useRef } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet-async";


function Contact() {
  const [modalMessage, setModalMessage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [desc, setDesc] = useState("");
  const [smShow, setSmShow] = useState(false);
  const [token, setToken] = useState("");
  const [formDisabled, setFormDisabled] = useState(true);
  const reRef = useRef();

  const clearFields = () => {
    setName("");
    setEmail("");
    setPhone("");
    setDesc("");
  };

  const onChange = (res) => {
    if (res) {
      setToken(res);
      setFormDisabled(false);
    }
  };

  const resetCaptcha = () => {
    setFormDisabled(true);
    reRef.current.reset();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormDisabled(true);
    const data = {
      name: name,
      phone: phone,
      email: email,
      desc: desc,
      recaptchaToken: token,
    };
    const requestOptions = {
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      method: "POST",
      body: JSON.stringify(data),
    };

    fetch(
      "https://103t0cb7vf.execute-api.eu-west-2.amazonaws.com/prod/contact/",
      requestOptions
    )
      .then((response) => {
        response.json().then((data) => {
          console.log(data);
          setModalMessage(
            "Thank you for contacting us, we will be in touch shortly."
          );
          setSmShow(true);
          clearFields();
          resetCaptcha();
        });
      })
      .catch((err) => {
        console.error(err);
        setModalMessage("Error sending message, please try again later.");
        setSmShow(true);
        resetCaptcha();
      });
  };

  return (
    <>
      <Modal
        size="sm"
        show={smShow}
        onHide={() => {
          setSmShow(false);
          /* clearFields(); */
        }}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Contact Form
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
      </Modal>

      <section id="contact">
        <Container>
          <Row>
            <Col xl={6}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d619.3477573364975!2d-0.25718203033883924!3d51.61604583096987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487616c11f7789ff%3A0x2feb7b2b3c5f570f!2sDigimasters%20Ltd!5e0!3m2!1sen!2suk!4v1707063295176!5m2!1sen!2suk%22"
                /* style="border:0" */
                width="100%"
                height="450"
                frameborder="0"
                title="digimasters-map"
              ></iframe>
            </Col>
            <Col xl={6}>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="nameInput">
                  <Form.Label>Name:</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="emailInput">
                  <Form.Label>Email address:</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="phoneInput">
                  <Form.Label>Phone:</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="descInput">
                  <Form.Label>Message:</Form.Label>
                  <Form.Control
                    required
                    as="textarea"
                    rows="3"
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                  />
                </Form.Group>
                <ReCAPTCHA
                  sitekey="6LdQy10UAAAAAK1t5zimrgR4osYqoJWZeEOvGiXD"
                  size="compact"
                  ref={reRef}
                  onChange={onChange}
                  onExpired={resetCaptcha}
                  onErrored={resetCaptcha}
                />
                <Button variant="primary" type="submit" disabled={formDisabled}>
                  Submit
                </Button>
              </Form>
              
              <div class="book-time-button">
                <Button variant="primary">
                  <a href="https://tidycal.com/adamnagus" target="_blank">Book Time With Adam</a>
                  </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Contact;
