import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap'
import { DataContext } from '../../context/DataContext'

const ClientsList = () => {
  const { clientsData } = useContext(DataContext)

  return (
    <section id="clients" className="clients">
      <Container>
        <div className="section-title" data-aos="fade-up">
          <h2>Our <strong>Clients</strong></h2>
        </div>
        <Row className="justify-content-center no-gutters clients-wrap clearfix" data-aos="fade-up">
          {clientsData &&
            clientsData.map((client, i) => {
            return <Col key={`key_${i}`} lg={3} md={4} xs={12}>
                {`client.isExternalPath` &&
                  <div className="client-logo">
                    <a href={`${client.client_site_url}`} target="_blank" rel="noopener noreferrer">
                      <img src={`/assets/clients/${client.client_logo}`} className="img-fluid" />
                    </a>
                  </div>
                }
                {!`client.isExternalPath` &&
                  <Link to={`clients/${client.path}`}  data-aos="fade-up">
                    <div className="client-logo">
                      <img src={`/assets/clients/${client.client_logo}`} className="img-fluid" alt="" />
                    </div>
                  </Link>
                }

              </Col>    
            })
          }
        </Row>
      </Container>
    </section>
  );
}

export default ClientsList