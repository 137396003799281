import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap'
import { DataContext } from '../../context/DataContext'
import ScrollToTop from  '../scrollToTop/ScrollToTop'
import { decodeHTML } from '../../modules/utilities'
import PartnerSocials from './PartnerSocials'

const PartnersList = () => {
  const { partnerData: data } = useContext(DataContext)

  return (
    <section id="partners" className="partners">
      <ScrollToTop />
      <Container>
        <Row>
          {
            data && data.map((item, i) => {
              return <Col lg={4} md={6} key={`key_${i}`}>
                <div data-aos="fade-up" className="box">
                  <Link to={`/partners/${item.path}`}>
                    <h3>{item.name}</h3>
                    <div className="client-logo" data-aos="fade-up">
                      <img src={`./assets/partners/${item.logo}`} className="img-fluid" alt="" />
                    </div>
                    <div className="misc-content" dangerouslySetInnerHTML={{__html: decodeHTML(item.short_desc)}}></div>
                  </Link>
                  <div className="entry-footer clearfix">
                    <div className="float-left">
                      <i className="icofont-folder"></i>
                      <ul className="cats">
                        <li><span className="tagName">{item.type}</span></li>
                      </ul>
                    </div>
                    <PartnerSocials item={item} />
                  </div>                  
                </div>
              </Col>
            })
          }
        </Row>
      </Container>
    </section>
  );
}

export default PartnersList