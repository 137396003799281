import React, { useContext } from "react";
import { DataContext } from "../../context/DataContext";
import { NavLink, useLocation } from "react-router-dom";
import { Nav } from "react-bootstrap";
import PropTypes from 'prop-types'

const checkActive = (pathname, rootName) => {
  return pathname.indexOf(`/${rootName}/`) !== -1 ? 'active' : ''
}

const MenuItems = ({ mobile }) => {
  const { clientsData, servicesData } = useContext(DataContext)
  const { pathname } = useLocation();

  return (
    <Nav as="ul" bsPrefix="dm">
      <Nav.Item as="li">
        <NavLink exact activeClassName="active" to="/home">
          Home
        </NavLink>
      </Nav.Item>
      <Nav.Item as="li">
        <NavLink exact activeClassName="active" to="/about">
          About
        </NavLink>
      </Nav.Item>
      {/* <Nav.Item as="li"><NavLink exact to="/partners">Partners</NavLink></Nav.Item> */}
      {/* <Nav.Item as="li" className={mobile ? '' : 'drop-down'}>
        <NavLink exact to='/clients' className={`menuCaption ${checkActive(pathname, "client")}`}>Clients</NavLink>
        {!mobile &&
          <Nav as="ul">
            {clientsData && clientsData.map((client, i) => {
              return (
                <Nav.Item as="li" key={`key_${i}`}>
                  <NavLink exact activeClassName="active" to={`/clients/${client.path}`}>{client.client_name}</NavLink>
                </Nav.Item>
              )
            })}
          </Nav>
        }
      </Nav.Item> */}
      <Nav.Item as="li" className={mobile ? '' : 'drop-down'}>
        <NavLink exact to='/services' className={`menuCaption ${checkActive(pathname, "service")}`}>Services</NavLink>
        {!mobile &&
          <Nav as="ul">
            {servicesData && servicesData.filter(item => item.visible === 1).map((item, i) => {
              return (
                <Nav.Item as="li" key={`key_${i}`}>
                  <NavLink exact activeClassName="active" to={`/services/${item.path}`}>{item.name}</NavLink>
                </Nav.Item>
              )
            })}
          </Nav>
        }
      </Nav.Item>
      {/* <Nav.Item as="li"><NavLink exact to="/careers">Careers</NavLink></Nav.Item> */}
      {/* <Nav.Item as="li"><NavLink exact to="/demos">Demos</NavLink></Nav.Item> */}
      <li class="nav-item"><a href="https://www.blog.digimasters.co.uk">Blog</a></li>
      {/* <Nav.Item as="li"><NavLink exact to="https://www.blog.digimasters.co.uk">Blog</NavLink></Nav.Item> */}
      <Nav.Item as="li"><NavLink exact to="/podcast">Podcast</NavLink></Nav.Item>
      <Nav.Item as="li">
        <NavLink exact activeClassName="active" to="/contact">
          Contact
        </NavLink>
      </Nav.Item>
    </Nav>
  );
};

MenuItems.propTypes = {
  mobile: PropTypes.bool
}

export default MenuItems;
