import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap'
import { DataContext } from '../../context/DataContext'
import { decodeHTML } from '../../modules/utilities'

const BlogList = () => {
  const { blogData } = useContext(DataContext)

  return (
    <section id="blog" className="blog">
      <Container>
        <Row className="entries">
            {blogData &&blogData.map((blog, i) => {
              return (
                <Col key={`key_${i}`} lg={6} className="entries">
                  <article className="entry" data-aos="fade-up">
                    <div className="entry-img">
                      <img src={`/assets/blogs/${blog.banner}`} alt="" className="img-fluid" />
                    </div>
                    <div className="entry-details">
                      <h2 className="entry-title">
                        <Link to={`/blogs/${blog.path}`}  data-aos="fade-up">{decodeHTML(blog.title).replace('<br>', '')}</Link>
                      </h2>
                      <div className="entry-meta">
                        <ul>
                          <li className="d-flex align-items-center"><i className="icofont-user"></i> <a href="blog-single.html">{blog.author}</a></li>
                          <li className="d-flex align-items-center"><i className="icofont-wall-clock"></i> <a href="blog-single.html"><time dateTime={blog.date}>{blog.date}</time></a></li>
                        </ul>
                      </div>
                      <div className="entry-content">
                        {/* <p>
                          Similique neque nam consequuntur ad non maxime aliquam quas. Quibusdam animi praesentium. Aliquam et laboriosam eius aut nostrum quidem aliquid dicta.
                          Et eveniet enim. Qui velit est ea dolorem doloremque deleniti aperiam unde soluta. Est cum et quod quos aut ut et sit sunt. Voluptate porro consequatur assumenda perferendis dolore.
                        </p> */}
                        <div className="read-more">
                          <Link to={`/blogs/${blog.path}`}  data-aos="fade-up">Read More</Link>
                        </div>
                      </div>
                    </div>
                  </article>
                </Col>
              )
            })}
        </Row>
      </Container>
    </section>
  );
}

export default BlogList