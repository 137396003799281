import React, { useContext, useEffect, useState } from 'react'
import { DataContext } from '../../context/DataContext'
import ScrollToTopOnMount from '../scrollToTop/ScrollToTopOnMount'
import { useParams, useHistory } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap'
import { arrayFrom, decodeHTML } from '../../modules/utilities'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

const BlogDetail = () => {
  const { blogData } = useContext(DataContext) // if there are going to lots of blogs might want to change this to pre-select the blog entry
  const history = useHistory()
  const [blog, setBlog] = useState()
  const [relatedData, setRelatedData] = useState()
  const [tags, setTags]= useState()
  let { blogpath } = useParams()
  
  useEffect(() => {
    if (blog) {
      const handleLinkClick = (e) => {
        e.preventDefault()
        
        const path = e.srcElement.getAttribute('data-route') || e.srcElement.getAttribute('href')
    
        if (path) {
          history.push(path)
        }
      }

      const links = document.querySelectorAll('.customLink')
  
      arrayFrom(links).forEach(link => {
        link.addEventListener('click', handleLinkClick )
      })
    }
  }, [blog, history])

  useEffect(() => {
    if (blogData && blog) {
      const c = blogData.filter(item => !blog.related || (blog.related && blog.related.indexOf(item.id) > -1))

      setRelatedData(c.length ? c : undefined)
    }
  }, [blogData, blog])

  useEffect(() => {
    if (blogData && blogpath) {
      const thisBlog = blogData.filter(item => item.path === blogpath)[0]

      if (thisBlog) {
        setBlog(thisBlog)
      } else {
        history.push('/blogs')
      }
    }
  }, [blogData, blogpath, history])

  useEffect(() => {
    if (blog && blog.tags) {
      const tagList = blog.tags.split(',').map(i => i = i.replace(/\s/g, ''))
      setTags(tagList)
    }
  }, [blog])

  return (
    <section id="blog" className="blog">
      {blog && 
        <Helmet>
          <meta property="og:url" content={`${process.env.REACT_APP_URL}/blogs/${blogpath}/seo.html`} />
          <meta name="keywords" content={blog.tags} />
          <meta itemProp="name" content={blog.title} />
          <meta name="twitter:title" content={blog.title} />

          <meta itemProp="description" content={blog.short_desc} />
          <meta name="description" content={blog.short_desc} />
          <meta name="twitter:description" content={blog.short_desc} />

          {/* For LinkedIn */}
          <meta property="og:title" content={blog.title} />
          <meta property="og:image" content={`${process.env.REACT_APP_URL}/social_media_thumbnails/${blog.social_media_thumbnail}`} />
          <meta property="og:description" content={blog.short_desc} />
          
          <meta name="image" content={`${process.env.REACT_APP_URL}/social_media_thumbnails/${blog.social_media_thumbnail}`} />
          <meta itemProp="image" content={`${process.env.REACT_APP_URL}/social_media_thumbnails/${blog.social_media_thumbnail}`} />
          <meta name="twitter:image" content={`${process.env.REACT_APP_URL}/social_media_thumbnails/${blog.social_media_thumbnail}`} />
        </Helmet>
}
      <ScrollToTopOnMount />
      <Container>
        <Row>
          <Col lg={relatedData ? 8 : 12}className="entries">
            {blog && 
              <article className="entry entry-single" data-aos="fade-up">
                {/* <div className="entry-img">
                  <img src={`./assets/blog/${blog.banner}`} alt="" className="img-fluid" />
                </div> */}
                <div className="entry-details">
                  <div className="share">
                    <a target="_blank" rel="noopener noreferrer" href={`https://twitter.com/intent/tweet?source=${process.env.REACT_APP_URL}/blogs/${blog.path}/seo.html&text=: ${process.env.REACT_APP_URL}/blogs/${blog.path}/seo.html`} title="Share on Twitter"><i className="icofont-twitter"></i></a>
                    <a target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_URL}/blogs/${blog.path}/seo.html`} title="Share on Facebook"><i className="icofont-facebook"></i></a>
                    <a target="_blank" rel="noopener noreferrer" href={`http://www.linkedin.com/shareArticle?mini=true&url=${process.env.REACT_APP_URL}/blogs/${blog.path}/seo.html&title=&summary=&source=http://www.linkedin.com/shareArticle?mini=true&url=${process.env.REACT_APP_URL}/blogs/${blog.path}/seo.html`} title="Share on LinkedIn"><i className="icofont-linkedin"></i></a>
                  </div>
                  <h2 className="entry-title" data-aos="fade-up">{decodeHTML(blog.title).replace('<br>', '')}
                    {/* <Link to={`/blog/${blog.path}`}  data-aos="fade-up">{decodeHTML(blog.title).replace('<br>', '')}</Link> */}
                  </h2>
                  <div className="entry-meta">
                    <ul>
                      <li className="d-flex align-items-center"><i className="icofont-user"></i> <a href="blog-single.html">{blog.author}</a></li>
                      <li className="d-flex align-items-center"><i className="icofont-wall-clock"></i> <a href="blog-single.html"><time dateTime={blog.date}>{blog.date}</time></a></li>
                    </ul>
                  </div>
                  <div className="entry-content misc-content">
                    <div className="misc-content" dangerouslySetInnerHTML={{__html: decodeHTML(blog.description)}}></div>
                  </div>
                </div>

                <div className="entry-footer clearfix">
                  <div className="float-left">
                    <i className="icofont-folder"></i>
                    <ul className="cats">
                      <li><span className="tagName">{blog.categories}</span></li>
                    </ul>

                    {tags && 
                      <>
                        <i className="icofont-tags"></i>
                        <ul className="tags">
                          {tags.map((item, i) => {
                            return <li key={`key_${i}`}><span className="tagName">{item}</span></li>
                          })}
                        </ul>
                      </>
                    }
                  </div>

                  <div className="float-right share">
                    <a target="_blank" rel="noopener noreferrer" href={`https://twitter.com/intent/tweet?source=${process.env.REACT_APP_URL}/blogs/${blog.path}/seo.html&text=: ${process.env.REACT_APP_URL}/blogs/${blog.path}/seo.html`} title="Share on Twitter"><i className="icofont-twitter"></i></a>
                    <a target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_URL}/blogs/${blog.path}/seo.html`} title="Share on Facebook"><i className="icofont-facebook"></i></a>
                    <a target="_blank" rel="noopener noreferrer" href={`http://www.linkedin.com/shareArticle?mini=true&url=${process.env.REACT_APP_URL}/blogs/${blog.path}/seo.html&title=&summary=&source=http://www.linkedin.com/shareArticle?mini=true&url=${process.env.REACT_APP_URL}/blogs/${blog.path}/seo.html`} title="Share on LinkedIn"><i className="icofont-linkedin"></i></a>
                  </div>
                </div>
              </article>
            }
          </Col>
          {relatedData && 
            <Col lg={4}>
              <div className="sidebar" data-aos="fade-left">
                <h3 className="sidebar-title">You might also like...</h3>
                <div className="sidebar-item recent-posts">
                  {relatedData && relatedData.map((item, i) => {
                    return <div key={`id_${i}`} className="post-item clearfix">
                      <img src={`/assets/blogs/${item.banner}`} alt=""  />
                      <h4>
                        <Link to={`/blogs/${item.path}`}  data-aos="fade-up">{decodeHTML(item.title).replace('<br>', '')}</Link>
                      </h4>
                      <time dateTime={item.date}>{item.date}</time>
                    </div>
                  })}
                </div>        
              </div>
            </Col>
          }
        </Row>
      </Container>
    </section>
  );
}

export default BlogDetail