import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap'
import { DataContext } from '../../context/DataContext'
import { decodeHTML } from '../../modules/utilities'
import ScrollToTop from  '../scrollToTop/ScrollToTop'

const Careers = () => {
  const { careersData } = useContext(DataContext)

  return (
    <section id="careers" className="careers">
      <ScrollToTop />
      <Container>
        <Row>
          {careersData &&
            careersData.map((item, i) => {
            return <Col key={`key_${i}`} lg={6} data-aos="fade-up">
                <Link to={`/careers/job_${item.job_id}`} data-aos="fade-up">
                  <div className={`career-item ${i > 1 ? 'mt-4' : ''}`}>
                    <h3>{item.role_name}</h3>
                    <h4>{item.location_of_role}</h4>
                    <table>
                      <tbody>
                        <tr>
                          <td className="career-label">Role Creation Date:</td>
                          <td>{item.role_creation_date}</td>
                        </tr>
                        <tr>
                          <td className="career-label">Role Start Date:</td>
                          <td>{item.role_start_date}</td>
                        </tr>
                        <tr>
                          <td className="career-label">Salary:</td>
                          <td>{decodeHTML(item.role_salary)}</td>
                        </tr>
                        <tr>
                          <td className="career-label">Role Length:</td>
                          <td>{item.role_length}</td>
                        </tr>
                        <tr>
                          <td className="career-label">Role Type:</td>
                          <td>{item.role_type}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Link>
              </Col>    
            })
          }
        </Row>
      </Container>
    </section>
  );
}

export default Careers