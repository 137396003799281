import React from "react";
import { Container, Row, Col } from 'react-bootstrap'
import { Helmet } from "react-helmet-async";
 
const Podcast = () => {
    return (

<section id="about-us" className="about-us podcast">
<Container>
  <Row className="no-gutters">
    <Col xl={12} className="col-xl-12 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch">
      <div className="content d-flex flex-column justify-content-center col-xl-12">
        <h3>Our Podcasts</h3>
        <p>Listen or watch our two podcast channels.</p>
      </div>
    </Col>
    <Col xl={4} className="image-dp ml-lg-5 ml-xl-0 d-flex align-items-stretch justify-content-center justify-content-lg-start" data-aos="fade-right"></Col>
    <Col xl={8} className="col-xl-8 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch">
      <div className="content d-flex flex-column justify-content-center col-xl-12">
        <h3>The Digimasters Podcast</h3>
        <p><a class="podcast-button" href="https://thedigimasterspodcast.buzzsprout.com" target="_blank">Listen Now</a>  <a class="podcast-button" href="https://www.youtube.com/channel/UCBC1dQtTL8GgVYPwGxZjIyA" target="_blank">Watch Now</a></p>
      </div>
    </Col>
    <Col xl={12} className="col-xl-12 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch">
      <div className="content d-flex flex-column justify-content-center col-xl-12">
        <p>The Digimasters Podcast is your regular overview of our planets best and brightest minds across Fractional Careers, Clean Energy, Hyper Digital and the Future of Education.</p>
        <p>With episodes on location, fireside chats, expert panels and classic round tables.</p>
        <p>You can watch episodes on our <a href="https://www.youtube.com/channel/UCBC1dQtTL8GgVYPwGxZjIyA">YouTube</a> channel as well as listen on your favourite podcast app.</p>
      </div>
    </Col>
    <Col xl={12} className="col-xl-8 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch">
      <div className="content d-flex flex-column justify-content-center col-xl-12">
        <div class="col-xl-12" id='buzzsprout-large-playerdp'></div>
        <Helmet>
          <script type='text/javascript' charset='utf-8' src='https://www.buzzsprout.com/2287781.js?container_id=buzzsprout-large-playerdp&player=large'></script>
        </Helmet>
      </div>
    </Col>
  </Row>
</Container>

<Container>
  <Row className="no-gutters">
    <Col xl={4} className="image-ds ml-lg-5 ml-xl-0 d-flex align-items-stretch justify-content-center justify-content-lg-start" data-aos="fade-right"></Col>
    <Col xl={8} className="col-xl-8 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch">
      <div className="content d-flex flex-column justify-content-center col-xl-12">
        <h3>Digimasters Shorts</h3>
        <p><a class="podcast-button" href="https://digimastersshorts.buzzsprout.com" target="_blank">Listen Now</a>  <a class="podcast-button" href="https://www.youtube.com/channel/UCKMcoPGRRhcOAGpnqOeLL3A" target="_blank">Watch Now</a></p>
      </div>
    </Col>
    <Col xl={12} className="col-xl-12 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch">
      <div className="content d-flex flex-column justify-content-center col-xl-12">
        <p>Digimasters Shorts is your daily dose of digital enlightenment, packed into quick 3-5 minute episodes.</p>
        <p>Specialising in Artificial Intelligence (AI), Digital News, Technology, and Data, this podcast brings you the latest and most significant updates from these ever-evolving fields.</p>
      </div>
    </Col>
    <Col xl={12} className="col-xl-8 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch">
      <div className="content d-flex flex-column justify-content-center col-xl-12">
        <div class="col-xl-12" id='buzzsprout-large-playerds'></div>
        <Helmet>
          <script type='text/javascript' charset='utf-8' src='https://www.buzzsprout.com/2292087.js?container_id=buzzsprout-large-playerds&player=large'></script>
        </Helmet>
      </div>
    </Col>    
  </Row>
</Container>

</section>
    );
};
 
export default Podcast;





/*import React from 'react';
import PodcastDetail from '../podcast/PodcastDetail'
//import ClientsList from '../clients/ClientsList'
import ScrollToTop from  '../scrollToTop/ScrollToTop'

function Podcast() {
  return (
    <>
      <ScrollToTop />
      <PodcastDetail />
    </>
  );
}

export default Podcast*/
