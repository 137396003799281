import React, { lazy, useContext, useEffect, useRef, useState } from 'react'

const defaultScrollProps = {
  x: 0,
  y: 0,
  verticalScroll: false
}

const SectionWithTouch = ({ children, className, handleTouchEnd }) => {
  const [initialClientXY, setInitialClientXY] = useState([0, 0])
  const currentScrollProps = useRef(defaultScrollProps)

  const handleTouchStart = (event) => {
    currentScrollProps.current = {
      x: event.nativeEvent.touches[0].clientX, 
      y: event.nativeEvent.touches[0].clientY,
      verticalScroll: false
    }
    setInitialClientXY([event.nativeEvent.touches[0].clientX, event.nativeEvent.touches[0].clientY])
  }

  const touchMove = (event) => {
    const clientY = event.nativeEvent.touches[0].clientY
    const vScroll = currentScrollProps.current.verticalScroll || Math.abs(currentScrollProps.current.y - clientY) > 10

    currentScrollProps.current = {
      x: event.nativeEvent.touches[0].clientX, 
      y: clientY,
      verticalScroll: vScroll
    }
  }

  const touchEnd = () => {
    const finalY = currentScrollProps.current.y

    if (Math.abs(currentScrollProps.current.x - initialClientXY[0]) > 10 && !currentScrollProps.current.verticalScroll) {
      console.log('handleChange', currentScrollProps.current.x < initialClientXY[0])
      if (handleTouchEnd) {
        handleTouchEnd(currentScrollProps.current.x < initialClientXY[0])
      }
    }
    currentScrollProps.current = defaultScrollProps
    setInitialClientXY([0, 0])
  }

  return (
    <section className={className} onTouchStart={handleTouchStart} onTouchMove={touchMove} onTouchEnd={touchEnd}>
      {children}
    </section>
  );
}

export default SectionWithTouch