import React, { lazy, useContext, useEffect, useRef, useState } from 'react'
import { DataContext } from '../../context/DataContext'
import ScrollToTop from '../scrollToTop/ScrollToTop'
import { useParams, useHistory } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap'
import { arrayFrom, decodeHTML } from '../../modules/utilities'
import SectionWithTouch from '../shared/SectionWithTouch'

import Diligence from '../diligence/Diligence'
import AIProductStrategy from '../aiproductstrategy/AIProductStrategy'
import GTMStrategy from '../gtm-strategy/GTMStrategy'
import VisualAnalytics from '../visual-analytics/VisualAnalytics'
import PartnerMgmt from '../partner-mgmt/PartnerMgmt'
import AnalyticsStrategy from '../analytics-strategy/AnalyticsStrategy'

// const importComponent = component =>
//   lazy(() =>
//     import(`./${component}`)
//     .catch(ex => {
//       return undefined
//     })
//   )

const ServiceDetail = () => {
  const { servicesData } = useContext(DataContext)
  const history = useHistory()
  const [visibleServices, setVisibleServices] = useState()
  const [service, setService] = useState()
  const currentIndex = useRef(-1)
  const [previousService, setPreviousService] = useState()
  const [nextService, setNextService] = useState()
  const [dynamicComponent, setDynamicComponent] = useState()

  let { servicepath } = useParams()

  
  useEffect(() => {
    let serviceIsVisible = false
    
    if (service) {
      const handleLinkClick = (e) => {
        e.preventDefault()
        
        const path = e.srcElement.getAttribute('data-route') || e.srcElement.getAttribute('href')
    
        if (path) {
          history.push(path)
        }
      }

      const links = document.querySelectorAll('.customLink')
  
      arrayFrom(links).forEach(link => {
        link.addEventListener('click', handleLinkClick )
      })
      
      serviceIsVisible = service.visible === 1
    }
    
    if (!serviceIsVisible) {
      setPreviousService(undefined)
      setNextService(undefined)
    }
  }, [service, history])

  // useEffect(() => {
  //   if (service && service.component) {
  //     (async () => {
  //       const View = await importComponent(service.component)
  //       // @ts-ignore
  //       if (View._status === -1) {
  //         setDynamicComponent(<View />)
  //       } else {
  //         setDynamicComponent(undefined)
  //       }
  //     })()
  //   } else {
  //     setDynamicComponent(undefined)
  //   }
  // }, [service])

  useEffect(() => {
    if (service && service.visible && visibleServices && currentIndex.current !== undefined) {
      if (currentIndex.current > 0) {
        setPreviousService(visibleServices[currentIndex.current - 1])
      } else {
        setPreviousService(undefined)
      }

      if (currentIndex.current < visibleServices.length - 1) {
        setNextService(visibleServices[currentIndex.current + 1])
      } else {
        setNextService(undefined)
      }
    }
  }, [visibleServices, service])
  
  useEffect(() => {
    if (servicesData && servicepath) {
      let index
      for (let i = 0; i < servicesData.length; i++) {
        if (servicesData[i].path === servicepath) {
          index = i
          break
        }
      }

      const thisService = servicesData.filter((item, i) => {
        return item.path === servicepath
      })[0]

      if (index !== undefined) {
        currentIndex.current = index
        setService(thisService)
      } else {
        history.push('/services')
      }
    }
  }, [servicesData, servicepath, history])

  useEffect(() => {
    if (servicesData) {
      setVisibleServices(servicesData.filter(i => i.visible === 1))
    }
  }, [servicesData])

  const handlePageClick = (next) => {
    let gotoPage
    if (next) {
      gotoPage = visibleServices[currentIndex.current + 1]
    } else {
      gotoPage = visibleServices[currentIndex.current - 1]
    }

    if (gotoPage) {
      history.push(`/services/${gotoPage.path}`)
    }
  }

  return (
    <>
      <ScrollToTop />
      {(previousService || nextService) &&
        <Container>
          {currentIndex !== undefined &&
            <Row style={{position: 'relative', minHeight: '25px'}}>
              {previousService &&
                <button className="pageButton" onClick={() => handlePageClick(false)}><i className="icofont-simple-left"></i>{previousService.name}</button>
              }
              <div className="dotContainer">
                {visibleServices && visibleServices.map((item, i) => { 
                  return <span key={`key_${i}`} className={`dot ${i === currentIndex.current ? 'active' : ''}`}></span>
                })}
              </div>
              {nextService &&
                <button className="pageButton right" onClick={() => handlePageClick(true)}>{nextService.name}<i className="icofont-simple-right"></i></button>
              }
            </Row>
          }
        </Container>
      }
      <SectionWithTouch className="services" handleTouchEnd={handlePageClick}>
        <Container>
          {service && 
            <Row>
              <Col sm={12}>
                <h2>Service: {service.name}</h2>
              </Col>
              <Col sm={12} className="misc-content"
                dangerouslySetInnerHTML={{__html: decodeHTML(service.description)}}
              />
            </Row>
          }
            <React.Suspense fallback='Loading views...'>
              <Row data-aos="fade-up">
                <>
                  {service?.component === 'Diligence' && <Diligence /> }
                  {service?.component === 'AIProductStrategy' && <AIProductStrategy /> }
                  {service?.component === 'GTMStrategy' && <GTMStrategy /> }
                  {service?.component === 'VisualAnalytics' && <VisualAnalytics /> }
                  {service?.component === 'PartnerMgmt' && <PartnerMgmt /> }
                  {service?.component === 'AnalyticsStrategy' && <AnalyticsStrategy /> }

                  {/* {dynamicComponent} */}
                </>
              </Row>
            </React.Suspense>
        </Container>
      </SectionWithTouch>
    </>
  );
}

export default ServiceDetail