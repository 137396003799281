import React from 'react';
import ScrollToTopOnMount from '../scrollToTop/ScrollToTopOnMount'
import ServicesList from '../services/ServicesList'

function Services() {
  return (
    <>
      <ScrollToTopOnMount />
      <ServicesList />
    </>
  );
}

export default Services