import React, { useContext, useEffect, useState } from 'react'
import { SRLWrapper } from 'simple-react-lightbox'
import { DataContext } from '../../context/DataContext'
import ScrollToTopOnMount from '../scrollToTop/ScrollToTopOnMount'
import { useParams } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap'
import { decodeHTML } from '../../modules/utilities'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

const lightRoomOptions = {
  buttons: {
    showAutoplayButton: false,
    showDownloadButton: false,
    showFullscreenButton: false,
    showThumbnailsButton: false
  },
  thumbnails: {
    thumbnailsContainerPadding : '30px 20px'
  }
}

const DemoDetail = () => {
  const { demosData } = useContext(DataContext) // if there are going to lots of demos might want to change this to pre-select the demo entry
  const [demo, setDemo] = useState()
  const [relatedData, setRelatedData] = useState()
  let { demopath } = useParams()

  useEffect(() => {
    if (demosData && demo) {
      const c = demosData.filter(item => demo.related === undefined ? false : demo.related.indexOf(item.id) > -1)
      setRelatedData(c.length ? c : undefined)
    }
  }, [demosData, demo])

  useEffect(() => {
    if (demosData && demopath) {
      const c = demosData.filter(item => item.path === demopath)[0]
      setDemo(c)
    }
  }, [demosData, demopath])

  return (
    <section id="demo" className="demo demoDetail">
      {demo && 
        <Helmet>
          <meta property="og:url" content={`${process.env.REACT_APP_URL}/demos/${demopath}/seo.html`} />
          <meta name="keywords" content='' />
          <meta itemProp="name" content={demo.title} />
          <meta name="twitter:title" content={demo.title} />

          <meta itemProp="description" content={demo.short_desc} />
          <meta name="description" content={demo.short_desc} />
          <meta name="twitter:description" content={demo.short_desc} />

          {/* For LinkedIn */}
          <meta property="og:title" content={demo.title} />
          <meta property="og:image" content={`${process.env.REACT_APP_URL}/social_media_thumbnails/${demo.social_media_thumbnail}`} />
          <meta property="og:description" content={demo.short_desc} />
          
          <meta name="image" content={`${process.env.REACT_APP_URL}/social_media_thumbnails/${demo.social_media_thumbnail}`} />
          <meta itemProp="image" content={`${process.env.REACT_APP_URL}/social_media_thumbnails/${demo.social_media_thumbnail}`} />
          <meta name="twitter:image" content={`${process.env.REACT_APP_URL}/social_media_thumbnails/${demo.social_media_thumbnail}`} />
        </Helmet>
}
      <ScrollToTopOnMount />
      <Container>
        <Row>
          <Col lg={relatedData ? 8 : 12}className="entries">
            {demo && 
              <article className="entry entry-single" data-aos="fade-up">
                <div className="entry-details">
                  <div className="share">
                    <a target="_blank" rel="noopener noreferrer" href={`https://twitter.com/intent/tweet?source=${process.env.REACT_APP_URL}/demos/${demo.path}/seo.html&text=: ${process.env.REACT_APP_URL}/demos/${demo.path}/seo.html`} title="Share on Twitter"><i className="icofont-twitter"></i></a>
                    <a target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_URL}/demos/${demo.path}/seo.html`} title="Share on Facebook"><i className="icofont-facebook"></i></a>
                    <a target="_blank" rel="noopener noreferrer" href={`http://www.linkedin.com/shareArticle?mini=true&url=${process.env.REACT_APP_URL}/demos/${demo.path}/seo.html&title=&summary=&source=http://www.linkedin.com/shareArticle?mini=true&url=${process.env.REACT_APP_URL}/demos/${demo.path}/seo.html`} title="Share on LinkedIn"><i className="icofont-linkedin"></i></a>
                  </div>
                  <h2 className="entry-title" data-aos="fade-up">{decodeHTML(demo.title).replace('<br>', '')}
                    {/* <Link to={`/demo/${demo.path}`}  data-aos="fade-up">{decodeHTML(demo.title).replace('<br>', '')}</Link> */}
                  </h2>
                  <div className="entry-content misc-content">
                    <div className="misc-content" dangerouslySetInnerHTML={{__html: decodeHTML(demo.description)}}></div>
                  </div>
                </div>
                <div className="entry-footer clearfix">
                  <div className="float-right share">
                    <a target="_blank" rel="noopener noreferrer" href={`https://twitter.com/intent/tweet?source=${process.env.REACT_APP_URL}/demos/${demo.path}/seo.html&text=: ${process.env.REACT_APP_URL}/demos/${demo.path}/seo.html`} title="Share on Twitter"><i className="icofont-twitter"></i></a>
                    <a target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_URL}/demos/${demo.path}/seo.html`} title="Share on Facebook"><i className="icofont-facebook"></i></a>
                    <a target="_blank" rel="noopener noreferrer" href={`http://www.linkedin.com/shareArticle?mini=true&url=${process.env.REACT_APP_URL}/demos/${demo.path}/seo.html&title=&summary=&source=http://www.linkedin.com/shareArticle?mini=true&url=${process.env.REACT_APP_URL}/demos/${demo.path}/seo.html`} title="Share on LinkedIn"><i className="icofont-linkedin"></i></a>
                  </div>
                </div>
                <SRLWrapper options={lightRoomOptions} >
                  <Row className="lightroom">
                    <Col lg={6}>
                      <img src={`/assets/demos/${demo.right_image1}`} className="img-fluid" alt="" />
                    </Col>
                    <Col lg={6}>
                      <div>
                        <img src={`/assets/demos/${demo.right_image2}`} className="img-fluid" alt="" />
                      </div>
                    </Col>
                  </Row>
                </SRLWrapper>
              </article>
            }
          </Col>
          {relatedData && 
            <Col lg={4}>
              <div className="sidebar" data-aos="fade-left">
                <h3 className="sidebar-title">You might also like...</h3>
                <div className="recent-posts">
                  {relatedData && relatedData.map((item, i) => {
                    return <Link key={`id_${i}`} to={`/demos/${item.path}`} data-aos="fade-up">
                      <div className="post-item sidebar-item clearfix">
                        <img src={`/assets/demos/${item.icon}`} alt=""  />
                        <h4>{decodeHTML(item.title).replace('<br>', '')}</h4>
                        <time dateTime={item.date}>{item.date}</time>
                      </div>
                    </Link>
                  })}
                </div>        
              </div>
            </Col>
          }
        </Row>
      </Container>
    </section>
  );
}

export default DemoDetail