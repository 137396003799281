import { useState, useEffect } from 'react';

const cache = {}

function useFetchJson(url, filterProp) {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (url && url !== '') {
      if (cache[url]) {
        setData(cache[url])
      } else {
        fetch(url)
        .then(response => response.json())
        .then(data => {
          let newData = data
          if (filterProp) {
            newData = newData.filter(item => {
              if (item[filterProp] !== undefined) {
                return item[filterProp]
              } else {
                return true
              }
            })
          }
          cache[url] = newData
          setData(newData)
        })
        .catch(err => {
          console.log('fetch json error', err)
        })
      }
    }
  }, [url, filterProp]);

  return data;
  
}

export default useFetchJson;