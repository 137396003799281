import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link
} from 'react-router-dom'
import SimpleReactLightbox from 'simple-react-lightbox'
import { DataProvider } from './context/DataContext'
// import ScrollToTop from  './components/scrollToTop/ScrollToTop'
import StickyHeader from './components/header/StickyHeader'
import Home from './components/pages/Home'
import PageWithBreadcrumbs from './components/pages/PageWithBreadcrumbs'
import About from './components/pages/About'

import Podcast from './components/pages/Podcast'

import ClientDetails from './components/clients/ClientDetail'
import ServiceDetails from './components/services/ServiceDetail'
import Careers from './components/careers/Careers'
import Footer from './components/footer/Footer'
import Contact from './components/pages/Contact'
import CareerDetails from './components/careers/CareerDetail'
import Services from './components/pages/Services'
import Clients from './components/pages/Clients'
import MobileMenu from './components/menu/MobileMenu'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import BlogList from './components/blog/BlogList'
import BlogDetails from './components/blog/BlogDetail'
import DemosList from './components/demos/DemosList'
import DemoDetails from './components/demos/DemoDetail'
import Privacy from './components/pages/Privacy'
import PartnersList from './components/partners/PartnersList'
import PartnerDetail from './components/partners/PartnerDetail'
// import TrainingList from './components/training/TrainingList'
import TrainingDetail from './components/training/TrainingDetail'
import './App.css';
import AOS from 'aos'
import 'aos/dist/aos.css'
import PressRelease from './components/pages/PressRelease'

const cookieName = 'complianceCookie'
const cookieValue = 'on'
const cookieDuration = 14

const createCookie = (name, value, days) => {
  let expires

  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = date.toUTCString()
  } else expires = ""

  document.cookie = `${name}=${value};expires=${expires}; path=/`
}

const checkCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == " ") c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
  }

  return null
}

function App() {
  const [showCookieConsent, setShowCookieConsent] = useState()

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
    });
  });

  useEffect(() => {
    if (checkCookie(cookieName) !== cookieValue) {
      setShowCookieConsent(true)
      createCookie(cookieName, cookieValue, cookieDuration)
    }
  }, [])

  return (
    <div className="App">
      <SimpleReactLightbox>
        <HelmetProvider>
          <Helmet>
            <meta name="twitter:card" content="summary"></meta>
            <meta name="twitter:site" content="@vis_analytics"></meta>
            <meta property="og:site_name" content="Digimasters"></meta>
            <meta property="og:type" content="website"></meta>

            <meta itemProp="name" content="Digimasters | Digital Diligence | GTM Strategy | Podcasts | Mentoring" />
            <meta name="twitter:title" content="Digimasters | Digital Diligence | GTM Strategy | Podcasts | Mentoring" />
            <meta property="og:title" content="Digimasters | Digital Diligence | GTM Strategy | Podcasts | Mentoring" />

            <meta itemProp="description" content="Digimasters | Digital Diligence | GTM Strategy | Podcasts | Mentoring" />
            <meta name="description" content="Digimasters | Digital Diligence | GTM Strategy | Podcasts | Mentoring" />
            <meta property="og:description" content="Digimasters | Digital Diligence | GTM Strategy | Podcasts | Mentoring" />
            <meta name="twitter:description" content="Digimasters | Digital Diligence | GTM Strategy | Podcasts | Mentoring" />

            <meta property="og:image" content="https://digimasters.co.uk/assets/img/logo_large.jpg" />
            <meta name="image" content="https://digimasters.co.uk/assets/img/logo_large.jpg" />
            <meta itemProp="image" content="https://digimasters.co.uk/assets/img/logo_large.jpg" />
            <meta name="twitter:image" content="https://digimasters.co.uk/assets/img/logo_large.jpg" />
          </Helmet>
          <DataProvider>
            <Router>
              {showCookieConsent && 
                <div className="cookie-law">
                  <p>Our website uses cookies. By continuing we assume your permission to deploy cookies, as detailed in our&nbsp;
                    <Link to='/privacy' rel="nofollow" title="Privacy & Cookies Policy">privacy and cookies policy.</Link>
                    <button className="close-cookie-banner" onClick={() => setShowCookieConsent(false)}><span>X</span></button>
                  </p>
                </div>
              }
              <StickyHeader />
              <Switch>
                <Route exact path="/" render={() => <Redirect to="/home" />} />
                <Route exact path="/home">
                  <Home />
                </Route>
                <PageWithBreadcrumbs exact path="/about" component={About} />
                <Route exact path="/contact">
                  <Contact />
                </Route>
                <PageWithBreadcrumbs exact path="/podcast" component={Podcast} />
                <PageWithBreadcrumbs exact path="/partners" component={PartnersList} />
                <PageWithBreadcrumbs exact path="/partners/:partnerpath" component={PartnerDetail} />
                <PageWithBreadcrumbs exact path="/clients" component={Clients} />
                <PageWithBreadcrumbs exact path="/clients/:clientpath" component={ClientDetails} />
                <PageWithBreadcrumbs exact path="/services" component={Services} />
                <PageWithBreadcrumbs exact path="/services/:servicepath" component={ServiceDetails} />
                <PageWithBreadcrumbs exact path="/careers" component={Careers} />
                <PageWithBreadcrumbs exact path="/careers/:jobId" component={CareerDetails} />
                <PageWithBreadcrumbs exact path="/demos" component={DemosList} />
                <PageWithBreadcrumbs exact path="/demos/:demopath" component={DemoDetails} />
                <PageWithBreadcrumbs exact path="/blogs" component={BlogList} />
                <PageWithBreadcrumbs exact path="/blogs/:blogpath" component={BlogDetails} />
                <PageWithBreadcrumbs exact path="/privacy" component={Privacy} />
                {/* <PageWithBreadcrumbs exact path="/training" component={TrainingList} /> */}
                <Route exact path="/training" render={() => <Redirect to="/services/training-and-support" />} />
                <PageWithBreadcrumbs exact path="/training/:trainingpath" component={TrainingDetail} />
                <PageWithBreadcrumbs exact path="/pressrelease/sale" component={PressRelease} />
                <Route>
                  <Home />
                </Route>
              </Switch>
              <Footer /> 
              <MobileMenu />
            </Router>
          </DataProvider>
        </HelmetProvider>
      </SimpleReactLightbox>
    </div>
  );
}

export default App;
