import React, { useState, useEffect } from 'react'
import { Route, useLocation, NavLink } from "react-router-dom"
import ScrollToTopOnMount from '../scrollToTop/ScrollToTopOnMount'

const displayName = (text) => {
  return text.replace(/[-_]/g, ' ')
}

const PageWithBreadcrumbs = ({component: Component, ...rest}) => {
  const location = useLocation()
  const [path, setPath] = useState()

  useEffect(() => {
    if (location && location.pathname) {
      const pathNames = location.pathname.split('/').slice(1)
      const displayPaths = pathNames.map((item, i) => {
        return {
          name: item,
          path: i < pathNames.length - 1 ? `/${pathNames.slice(0, i + 1).join('/')}` : undefined

        }
      })

      setPath(displayPaths)
    }
  }, [location])

  return (
    <Route {...rest} render={matchProps => (
      <div className="defaultLayout">
        <ScrollToTopOnMount />
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              {path && 
                <>
                  <h2 className="pathName">{displayName(path[path.length - 1].name)}</h2>
                  <ol>
                    <li><NavLink exact activeClassName="active" to="/home">HOME</NavLink></li>
                    {path && path.map((item, i) => {
                      return (
                        <li key={`key_${i}`} className="pathName">
                          {item.path && 
                            <NavLink exact activeClassName="active" to={item.path}>{item.name}</NavLink>
                          }
                          {!item.path && displayName(item.name)}
                        </li>
                      )
                    })}
                  </ol>
                </>
              }
            </div>
          </div>
        </section>
        <Component {...matchProps} />
      </div>
    )} />
  )
};

export default PageWithBreadcrumbs