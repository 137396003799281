import React from "react";
import { Container } from "react-bootstrap";
import Menu from '../menu/Menu'

const Header = () => {
  return (
    <header id="header">
      <Container className="d-flex">
        <div className="logo mr-auto">
          <h1 className="text-light digi-font">
            <a href="/index.html"><img src="/assets/img/logo.png" className="img-fluid logo grow" />Digimasters</a>
          </h1>
        </div>
        <Menu />
      </Container>
    </header>
  );
};

export default Header
