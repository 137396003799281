import React, { useEffect, useState } from 'react';
import useFetchJson from '../components/hooks/useFetchJson'
  
const DataContext = React.createContext();

const DataProvider = ({ children } ) => {
  const particleConfig = useFetchJson('/config/particles.json')
  const clientsData = useFetchJson('/config/clients.json', 'visible')
  const servicesRawData = useFetchJson('/config/services.json')
  const demosData = useFetchJson('/config/demos.json')
  const pagesData = useFetchJson('/config/pages.json')
  const careersData = useFetchJson('/config/careers.json')
  const blogRawData = useFetchJson('/config/posts.json')
  const partnerData = useFetchJson('/config/partners.json')
  const trainingData = useFetchJson('/config/training.json')
  const [servicesData, setServicesData] = useState()
  const [aboutData, setAboutData] = useState()
  const [blogData, setBlogData] = useState()
  

  useEffect(() => {
    if (servicesRawData) {
      setServicesData(servicesRawData) // .filter(item => item.visible === 1))
    }
  }, [servicesRawData])

  useEffect(() => {
    if (pagesData) {
      const data = pagesData.filter(item => item.content_title === 'About Us')[0]
      setAboutData(data)
    }
  }, [pagesData])

  useEffect(() => {
    if (blogRawData) {
      const data = blogRawData.filter(item => item.visible === 1).sort((a, b) => {
        const aDate = new Date(a.date)
        const bDate = new Date(b.date)
        return bDate < aDate ? -1 : 0
      })
      setBlogData(data)
    }
  }, [blogRawData])

  return (
    <DataContext.Provider value={{ particleConfig, clientsData, servicesData, demosData, pagesData, careersData, aboutData, blogData, partnerData, trainingData }}>
      {children}
    </DataContext.Provider>
  );
}

export { DataContext, DataProvider };