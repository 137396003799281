import React from 'react';
import { Link } from 'react-router-dom'

const FooterSocials = () => {
  return (
    <div className="container d-md-flex py-4">
      <div className="mr-md-auto text-center text-md-left">
        <div className="copyright">
          &copy; Copyright <strong><span>Digimasters</span></strong>. All Rights Reserved
        </div>
        <div className="credits">
          Designed by <Link to={'/home'}>Carly, Mark and Nic</Link>
        </div>
      </div>
      <div className="social-links text-center text-md-right pt-3 pt-md-0">
        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/vis_analytics" className="twitter"><i className="bx bxl-twitter"></i></a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/DigimastersUK/" className="facebook"><i className="bx bxl-facebook"></i></a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCKMcoPGRRhcOAGpnqOeLL3A" className="youtube"><i className="bx bxl-youtube"></i></a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/digimastersshorts/" className="instagram"><i className="bx bxl-instagram"></i></a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/18573778/" className="linkedin"><i className="bx bxl-linkedin"></i></a>
      </div>
    </div>
  );
}

export default FooterSocials