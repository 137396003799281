import React from 'react';
import FooterSocials from './FooterSocials.js'
import FooterLinks from './FooterLinks.js'

const Footer = () => {
  return (
    <footer id="footer">
      <FooterLinks />
      <FooterSocials />
    </footer>
  );
}

export default Footer