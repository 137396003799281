import React from 'react';
import { NavLink } from "react-router-dom";

const FooterLinks = () => {
  return (
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 footer-contact">
            <h3 className="digi-font">Digimasters</h3>
            <p>
              {/* <strong>Email:&nbsp;</strong><a href="mailto:info@digimasters.co.uk">info@digimasters.co.uk</a><br/> */}

              <NavLink exact activeClassName="active" to="/contact">
                <strong>Contact</strong>
              </NavLink>
            </p>
          </div>

          <div className="col-lg-2 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i><NavLink exact activeClassName="active" to='/services'>Services</NavLink></li>
              {/* <li><i className="bx bx-chevron-right"></i> <a href="#">Terms of service</a></li> */}
              <li><i className="bx bx-chevron-right"></i>
                <NavLink exact to="/privacy">Privacy policy</NavLink>
              </li>
            </ul>
          </div>

          {/* <div className="col-lg-3 col-md-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Web Design</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Web Development</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Product Management</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Marketing</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Graphic Design</a></li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default FooterLinks