import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

function PressRelease() {
  return (
    <section>
      <Container>
        <Row className="top-row">
          <Col>
            <img src="/assets/pressrelease/FTIlog.png" className="img-fluid" />
          </Col>
          <Col>
            <img src="/assets/pressrelease/digilogo.png" className="img-fluid" />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="green-panel">
              <div className="green-panel-header">
                <span>
                  Press Release: Digimasters Ltd Acquisition by FTI
                  Consulting’s Digital Science Platform
                </span>
              </div>
              <Row className="green-panel-body">
                <Col xs={12} sm={12} md={7}>
                  <p className="pr-date">3rd July 2019</p>
                  <p>
                    I am delighted to announce that the visual analytics
                    company I started in 2017, Digimasters Ltd has been
                    acquired by the Digital Science Group, part of FTI
                    Consulting LLP.
                  </p>
                  <p>
                    This acquisition creates a new Visual Intelligence
                    capability focusing on delivering visual analytics
                    services, technology alliances and UI and UX design
                    capabilities for FTI’s EMEA clients. I have joined FTI as
                    Managing Director of Visual Intelligence and I look
                    forward to helping our clients use leading edge
                    visualization to create new value and insights from data.
                  </p>
                  <p>
                    For more information on Digital Science and the
                    acquisition please contact Adam Nagus.
                  </p>
                </Col>
                <Col xs={12} sm={12} md={5}>
                  <img
                    src="/assets/pressrelease/Photo.png"
                    className="img-fluid adam-portrait"
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className="footer-row">
          <Col>
            <div className="footer">
              <div>Related Links:</div>
              <div className="prFooterLinks">
                <div>
                  <a href="https://youtu.be/pwrst1aa2zs" target="_blank">
                    <img
                      src="/assets/pressrelease/youtube link.png"
                      className="footer-img"
                    />
                  </a>
                </div>
                <div>
                  <a href="https://youtu.be/nNnHJCSb8f0" target="_blank">
                    <img
                      src="/assets/pressrelease/youtube link.png"
                      className="footer-img"
                    />
                  </a>
                </div>
                <div>
                  <a href="https://youtu.be/VVz7qMDx1Js" target="_blank">
                    <img
                      src="/assets/pressrelease/youtube link.png"
                      className="footer-img"
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.linkedin.com/in/adamnagus/"
                    target="_blank"
                  >
                    <img
                      src="/assets/pressrelease/linkedin link.png"
                      className="footer-img"
                    />
                  </a>
                </div>
                <div>
                  <a href="http://digimasters.co.uk" target="_blank">
                    <img
                      src="/assets/pressrelease/Digimasters link.png"
                      className="footer-img"
                    />
                  </a>
                </div>
                <div>
                  <a href="https://fticonsulting.com" target="_blank">
                    <img src="/assets/pressrelease/FTI link.png" className="footer-img" />
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default PressRelease
