import React from 'react';
// import TopBar from '../topbar/TopBar'
import Header from './Header'
import BurgerMenu from '../menu/BurgerMenu';

const StickyHeader = () => {
  return (
    <div className="sticky-header">
      {/* <TopBar /> */}
      <Header />
      <BurgerMenu />
    </div>
  );
}

export default StickyHeader